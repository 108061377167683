/*font-family: 'Lora', serif;*/
/*font-family: 'Cabin', sans-serif;*/
html, body, #root, .App {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}
.App {
  /*overflow-y: scroll!important;*/
}
.pushable {
  /*overflow-y: hidden;*/
}
.appBody {
  background-color: #7a9bbc;
  background-image: url("../public/images/asfalt-light.png");
}
#menu {
  background-color: #6083A5;
  /*width: 280px!important;*/
  height: 163px!important;
  float: right!important;
  border-bottom-left-radius: 18px!important;
  /*align-self: flex-end!important;*/
}
.sidebarMenu {
  /*padding: 20px 10px;*/
  display: flex;
  flex-direction: column;
  /*align-items: flex-end;*/
}
.sidebarMenu i {
  margin: 5px 10px 0px;
  line-height: 36px;
}
.sidebarMenu span, .sidebarMenu a, .sidebarMenu i {
  align-self: flex-end;
}
.sidebarMenu span, .sidebarMenu a {
  font-family: "Cabin", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  padding: 10px 50px;
}
.sidebarMenu span:hover {
  cursor: pointer;
}
.sidebarMenu .divider {
  border-top: 1px solid #326496!important;
  border-bottom: 0px!important;
  margin: 0px!important;
}
.header {
  /*padding: 20px 15px;*/
  /*display: flex;*/
  /*justify-content: space-between;*/
}
.mobileHeader {
  width: 100%;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
}
.desktopHeader {
  display: none;
}
#badgeImg {
  display: none;
}
.header img {
  width: 215px;
  height: 80px;
}
.header i, #menu > div:nth-child(1) > i {
  font-size: 36px!important;
  color: #fff;
}

.section {
  margin-top: 25px;
  width: 380px;
  background-color: #fff;
  border-radius: 0px 18px 18px 0px;
}
.sectionHeader {
  background: linear-gradient(180deg, #fff 0%, #b7c3cf 100%);
  padding: 28px 0px 10px 37px;
  border-top-right-radius: 18px;
  display: flex;
  align-items: flex-end;
}
.sectionHeader span {
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #326496;
  margin-bottom: 8px;
}
.section .headerBarContainer {
  /*width: 100%;*/
  display: flex;
  flex-direction: column;
}
.sectionHeader .headerBar {
  height: 20px;
  width: 220px;
  background: linear-gradient(-63deg, #8c9fb2 0%, #276b9b 100%);
}
.sectionHeader svg {
  display: none;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 16px 57px;
}
.contentLabel {
  font-family: 'Cabin', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #505050;
  margin-bottom: 15px;
}
.content {
  font-family: 'Lora', serif;
  font-size: 16px;
  line-height: 24px;
  color: #505050;
}

.form {
  margin-top: 8px;
}
.form input, .form textarea {
  border-radius: 0px!important;
  border-top: 0px!important;
  border-left: 0px!important;
  border-right: 0px!important;
  padding: 4px 8px!important;
  font-family: Lato, 'Helvetica Neue', Arial,Helvetica, sans-serif
}

.imageContainer {
  background-color: rgba(39, 105, 152, .2);
  width: 300px;
  margin: 45px auto 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 9px;
  padding: 60px 25px 0px!important;
}
.imageContainer img {
  width: 250px;
  margin-bottom: 60px;
  border: 3px solid #7A9BBC;
}

.productsHeader {
  padding: 13px 19px;
  background-color: rgba(61, 132, 180, 0.55);
  font-family: "Cabin", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #e8e8e8;
  text-align: center;
  margin-bottom: 24px;
}
.products {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.productsRow {
  display: flex;
  flex-direction: column;
}
.product {
  width: 335px;
}
.prodHeader {
  padding: 18px 10px 14px 26px;
  background-color: #c3d2e0;
  border-radius: 18px 18px 0px 0px;
}
.prodLabel {
  font-family: "Cabin", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #326496;
}
.prodContent {
  background-color: #f0f2f5;
  border-radius: 0px 0px 18px 18px;
  /*padding: 22px 36px 0px;*/
  width: 100%;
  height: 293px;
  /*padding: 22px 100px;*/
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Cabin", sans-serif;
}
.prodContent img {
  width: 191px;
  height: 249px;
}
.prodContent img:hover {
  cursor: pointer;
}
#psVideo, #psGreen {
  /*height: 293px;*/
  width: 300px;
}
#psGreen {
  /*width: 300px;*/
}
.infoHeader {
  font-weight: 600;
  font-size: 18px;
  color: #505050;
  margin-bottom: 2px;
}
.infoContent {
  color: #505050;
}
body > div.ui.page.modals.dimmer.transition.visible.active > div > div.content {
  display: flex!important;
  flex-direction: column!important;
  align-items: center!important;
  padding: 10px 0px 0px 0px!important;
}
.modal .content span {
  text-align: center;
  margin: 0px 10px 10px;
}
.modal .content button {
  width: 100%;
  height: 50px;
  border-radius: 0px 0px 3px 3px;
  font-size: 15px!important;
}

.footer {
  background-color: #666666;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px 0px;
  -webkit-box-shadow: inset 0px 1px 4px 0px #000000;
  box-shadow: inset 0px 1px 4px 0px #000000;
}
.footer span {
  font-size: 12px;
  color: #e8e8e8;
  font-family: 'Cabin', sans-serif;
}

@media screen and (min-width: 500px) {
  .section {
    width: 450px;
  }
}
@media screen and (min-width: 600px) {
  .section {
    width: 550px;
  }
  .product {
    width: 380px;
  }
  .mobileHeader {
    display: none;
  }
  .desktopHeader {
    display: block;
    background-image: url("../public/images/header-image.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0px 45%;
    height: 356px;
  }
  .desktopHeader i {
    margin-top: 20px;
    margin-right: 15px;
  }
  .dHeaderContainer {
    display: flex;
    justify-content: space-between;
  }
  #badgeImg {
    display: block;
    position: relative;
    /*top: 0px;*/
    /*left: 0px;*/
    width: 470px;
    height: 185px;
  }
  .pushable {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
}
@media screen and (min-width: 730px) {
  .section {
    width: 380px;
  }
  .home {
    display: flex;
    flex-direction: row;
  }
  .information {
    margin-bottom: 20px;
  }
  .imageContainer {
    margin-top: 25px;
  }
  .product {
    /*width: 420px;*/
  }
}
@media screen and (min-width: 800px) {
  .productsRow {
    flex-direction: row;
    width: 770px;
    justify-content: space-between;
  }
}
@media screen and (min-width: 900px) {
  .section {
    width: 550px;
  }
  .headerBar {
    width: 380px!important;
  }
  .sectionHeader svg {
    display: block;
    width: 63px!important;
    height: 61px;
    color: #7A9BBC;
    margin-left: 6px;
  }
  #root > div > div.pusher > div > div.home > div.information > div:nth-child(1) > div.sectionHeader > svg {
    transform: rotate(270deg);
  }
  #root > div > div.pusher > div > div.home > div.information > div:nth-child(3) > div.sectionHeader > svg {
    margin-bottom: -8px;
  }
  .productsRow {
    /*flex-direction: row;*/
    width: 800px;
    /*justify-content: space-between;*/
  }
}
@media screen and (min-width: 1000px) {
  .section {
    width: 675px;
  }
  .productsRow {
    width: 900px;
  }
  .product {
    width: 440px;
  }
  .prodContent {
    width: 440px;
    height: 290px;
    padding: 22px 100px;
  }
}
@media screen and (min-width: 1200px) {
  .section {
    width: 700px;
  }
  .imageContainer {
    width: 350px;
  }
  .imageContainer img {
    width: 290px;
    border: 8px solid #7A9BBC;
  }
  .productsRow {
    width: 980px;
  }
}
@media screen and (min-width: 1500px) {
  .imageContainer {
    width: 700px;
    padding: 20px!important;
  }
  .imageContainer img:nth-child(4) {
    margin-bottom: 0px;
  }
  /*.imageContainer1 {*/
  /*  width: 100%;*/
  /*}*/
  .imageContainer img {
    width: 425px;
    margin-bottom: 20px;
  }
}